export default {
  inbox: 'Inbox',
  today: 'Today',
  week: 'This Week',
  month: 'This Month',
  quarter: 'This Quarter',
  year: 'This Year',
  lustrum: 'This Lustrum',
  decade: 'This Decade',
};
